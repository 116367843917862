// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
    jQuery(".menu_toggle").on("click", function () {
        jQuery("body").toggleClass("navigation-open");
    });

    jQuery(".search_toggle").on("click", function () {
        jQuery("body").toggleClass("search-open");
    });

    jQuery(".banner_slider").slick({
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: false,
        // autoplay: true,
        // autoplaySpeed: 15000,
        slidesToShow: 1,
        pauseOnHover: true,
    });

    jQuery("body p").each(function () {
        const $this = jQuery(this);
        if ($this.html().replace(/\s|&nbsp;/g, "").length == 0) {
            $this.remove();
        }
    });
});
